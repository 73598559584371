<template>
  <!-- Item of the CityList -->
  <div id="city-list-item" class="city-list-item" @click="redirect()">
    <div v-if="!isMobile">
      <v-avatar size="50">
        <v-img :src="require('@/assets/images/' + city[0] + '.jpg')" />
      </v-avatar>
    </div>
    <div class="city-list-item-col-name">
      <p class="city-list-item-name-mobile" :style="'color: ' + color + ''">
        {{ $t("city." + city[0] + ".name") }}
      </p>
    </div>
    <div v-if="!isMobile" class="city-list-item-col">
      <p :style="'color: ' + color + ''">{{ city[1].PopTot.toFixed(1) }} M</p>
    </div>
    <div v-if="!isMobile" class="city-list-item-col">
      <p :style="'color: ' + color + ''">
        {{ Math.round(city[1].Tot) }} km&#xB2;
      </p>
    </div>
    <div class="city-list-item-col">
      <p v-if="city[1].weather" :style="'color: ' + color + ''">
        {{
          (city[1].weather.meanDeviationYearly.C[
            city[1].weather.meanDeviationYearly.C.length - 1
          ].toFixed(2) > 0
            ? "+"
            : "") +
          $n(
            city[1].weather.meanDeviationYearly.C[
              city[1].weather.meanDeviationYearly.C.length - 1
            ].toFixed(2)
          ) +
          "°C"
        }}
      </p>
      <p v-else>NoData</p>
    </div>
    <div class="city-list-item-col">
      <p :style="'color: ' + color + ''">{{ Math.round(city[1].PercVeg) }}</p>
    </div>
    <div class="chevron-mobile">
      <!-- redirect to the city page -->
      <v-btn
        x-small
        fab
        depressed
        color="#ffffff00"
        :href="$i18n.locale === 'fr' ? '/ville/' + city[0] : '/' + city[0]"
      >
        <v-icon color="#113689">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "CityListItem",
  filters: {
    percentage: function (value) {
      return value.toLocaleString("en", { style: "percent" });
    },
  },
  props: {
    color: {
      type: String,
      required: false,
      default: "",
    },
    // a city object, which be displayed with the component
    city: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    redirect() {
      if (this.$i18n.locale === "fr") {
        location.href = "/ville/" + this.city[0];
      } else {
        location.href = "/" + this.city[0];
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 800px) {
  .city-list-item {
    grid-template-columns: 3fr 2fr 2fr;
    gap: 1px;
  }

  @media screen and (orientation: portrait) {
    .city-list-item-col-name {
      font-size: 14px;
    }

    .city-list-item-name-mobile {
      position: relative;
      right: 20px;
    }

    .chevron-mobile {
      position: absolute;
      right: -10px;
      padding-bottom: 1%;
    }
  }

  @media screen and (orientation: landscape) {
    .city-list-item {
      grid-template-columns: 3fr 3fr 3fr 1fr;
      gap: 2px;
    }
  }
}
</style>
