<template>
  <div>
    <div v-if="displayedCityCards" v-show="displayList" class="city-list-list">
      <!-- Properties of the list -->
      <div class="city-list-title">
        <div v-if="!isMobile" />
        <div class="city-list-title-class">
          <v-btn
            rounded
            :outlined="city ? false : true"
            :color="city ? '#69b970' : '#294994'"
            elevation="0"
            style="text-transform: inherit"
            :small="isMobile"
            @click="sortBy('city')"
          >
            <p class="filter-class" :style="!city ? '' : 'color: white'">
              {{ $t("featured.city-list-title.city") }}
            </p>
            <v-icon v-show="up && city" small class="ml-1" color="white"
              >mdi-arrow-up</v-icon
            >
            <v-icon v-show="!up && city" small class="ml-1" color="white"
              >mdi-arrow-down</v-icon
            >
          </v-btn>
        </div>
        <div v-if="!isMobile" class="city-list-title-class">
          <v-btn
            rounded
            :outlined="population ? false : true"
            :color="population ? '#69b970' : '#294994'"
            elevation="0"
            style="text-transform: inherit"
            @click="sortBy('population')"
          >
            <p class="filter-class" :style="!population ? '' : 'color: white'">
              {{ $t("featured.city-list-title.population") }}
            </p>
            <v-icon v-show="up && population" class="ml-1" color="white"
              >mdi-arrow-up</v-icon
            >
            <v-icon v-show="!up && population" class="ml-1" color="white"
              >mdi-arrow-down</v-icon
            >
          </v-btn>
        </div>
        <div v-if="!isMobile" class="city-list-title-class">
          <v-btn
            rounded
            :outlined="area ? false : true"
            :color="area ? '#69b970' : '#294994'"
            elevation="0"
            style="text-transform: inherit"
            @click="sortBy('area')"
          >
            <p class="filter-class" :style="!area ? '' : 'color: white'">
              {{ $t("featured.city-list-title.urban-area") }}
            </p>
            <v-icon v-show="up && area" class="ml-1" color="white"
              >mdi-arrow-up</v-icon
            >
            <v-icon v-show="!up && area" class="ml-1" color="white"
              >mdi-arrow-down</v-icon
            >
          </v-btn>
        </div>
        <div class="city-list-title-class city-list-temperature-button">
          <info-btn v-if="isMobile" :is-mobile="isMobile" :temperature="true" />
          <v-btn
            :rounded="
              !isMobile || (!isMobile ? (temperature ? false : true) : true)
            "
            :icon="isMobile && (temperature ? false : true)"
            :outlined="temperature ? false : true"
            :color="temperature ? '#69b970' : '#294994'"
            elevation="0"
            style="text-transform: inherit"
            large
            @click="sortBy('temperature')"
          >
            <p
              v-if="!isMobile"
              class="filter-class"
              :style="!temperature ? '' : 'color: white'"
            >
              <!-- {{ $t("featured.city-list-title.temperatureDifference") }} -->
              {{ $t("featured.city-list-title.temperatureDifference1") }}<br />
              {{ $t("featured.city-list-title.temperatureDifference2") }}
            </p>
            <v-icon v-if="isMobile">$vuetify.icons.mean_deviation</v-icon>
            <v-icon
              v-show="up && temperature"
              :small="isMobile"
              class="ml-1"
              color="white"
              >mdi-arrow-up</v-icon
            >
            <v-icon
              v-show="!up && temperature"
              :small="isMobile"
              class="ml-1"
              color="white"
              >mdi-arrow-down</v-icon
            >
          </v-btn>
        </div>
        <div class="city-list-title-class">
          <info-btn
            v-if="isMobile"
            :is-mobile="isMobile"
            :temperature="false"
          />
          <v-btn
            :rounded="
              !isMobile || (!isMobile ? (temperature ? false : true) : true)
            "
            :icon="isMobile && (klover ? false : true)"
            :outlined="klover ? false : true"
            :color="
              isMobile
                ? klover
                  ? '#FFCA00'
                  : '#294994'
                : klover
                ? '#69b970'
                : '#294994'
            "
            elevation="0"
            style="text-transform: inherit"
            large
            @click="sortBy('klover')"
          >
            <p
              v-if="!isMobile"
              class="filter-class"
              :style="!klover ? '' : 'color: white'"
            >
              <!-- {{ $t("featured.city-list-title.greenness") }} -->
              {{ $t("featured.city-list-title.greenness1") }}<br />
              {{ $t("featured.city-list-title.greenness2") }}
            </p>
            <v-icon v-if="isMobile">$vuetify.icons.klover_score</v-icon>
            <v-icon
              v-show="up && klover"
              :small="isMobile"
              class="ml-1"
              color="white"
              >mdi-arrow-up</v-icon
            >
            <v-icon
              v-show="!up && klover"
              :small="isMobile"
              class="ml-1"
              color="white"
              >mdi-arrow-down</v-icon
            >
          </v-btn>
        </div>
        <div />
      </div>

      <CityListItem
        v-for="(cityC, i) in cityArray"
        :key="i"
        :is-mobile="isMobile"
        :city="cityC"
        :color="updateColor(i)"
      />
    </div>
  </div>
</template>

<script>
import CityListItem from "@/components/Featured/CityListItem";
import kloverData from "@/assets/klover_data.json";

import axios from "axios";
import InfoBtn from "../Description/information/InfoBtn.vue";

export default {
  name: "CityList",
  components: {
    CityListItem,
    InfoBtn,
  },
  data: function () {
    return {
      // if true cityList is displayed, if false cityCards are displayed
      displayList: true,
      // the JSON which contains all the city data
      cityArray: Object.entries(kloverData),
      // number of cityCards displayed at the render of the page
      displayedCityCardNumber: 10,
      displayedCityCards: null,
      data: kloverData,

      temperatureDifferenceReversed: true,
      nameReversed: true,
      greeneryReversed: true,
      city: false,
      population: false,
      area: false,
      temperature: false,
      klover: false,
      up: false,
      isMobile: false,
    };
  },
  // Sort cities according to there local names.
  watch: {
    "$i18n.locale": function () {
      this.sortCitiesByName();
    },
  },
  created() {},
  async mounted() {
    // sort cities by name at the beginning

    await this.getWeatherData();
    this.cityArray.sort((a, b) =>
      this.$t("city." + a[0] + ".name") > this.$t("city." + b[0] + ".name")
        ? 1
        : -1
    );

    this.displayedCityCards = this.cityArray.slice(0, 10);

    this.displayedCityCards.sort((a, b) =>
      this.$t("city." + a[0] + ".name") > this.$t("city." + b[0] + ".name")
        ? 1
        : -1
    );

    this.isMobile = window.innerWidth <= 800;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 800;
    });
  },
  methods: {
    // get weather data
    async getWeatherData() {
      let meanDeviationResponses;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        meanDeviationResponses = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + "/mean_deviation_yearly"
        );
        meanDeviationResponses = meanDeviationResponses.data;
        // Add weather data to the city array
        const meanDeviationResponsesArray = Object.entries(
          meanDeviationResponses
        );

        for (
          let index = 0;
          index < meanDeviationResponsesArray.length;
          index++
        ) {
          this.cityArray[index][1].weather = {};
          this.cityArray[index][1].weather.meanDeviationYearly =
            meanDeviationResponsesArray[index][1];
        }
      } catch (error) {
        console.error(error);
      }
    },
    sortBy(event) {
      this.reset();
      if (this.up) {
        this.up = false;
      } else {
        this.up = true;
      }
      if (event === "city") {
        this.city = true;
        this.sortCitiesByName();
      } else if (event === "population") {
        this.population = true;
        this.sortCitiesByPopulation();
      } else if (event === "area") {
        this.area = true;
        this.sortCitiesByArea();
      } else if (event === "temperature") {
        this.temperature = true;
        this.sortCitiesByTemperatureDeviation();
      } else {
        this.klover = true;
        this.sortCitiesByGreenery();
      }
    },
    reset() {
      this.city = false;
      this.population = false;
      this.area = false;
      this.temperature = false;
      this.klover = false;
    },
    sortCitiesByArea() {
      if (this.up) {
        this.cityArray.sort((a, b) => (a[1].Tot > b[1].Tot ? 1 : -1));

        this.displayedCityCards.sort((a, b) => (a[1].Tot > b[1].Tot ? 1 : -1));
      } else {
        this.cityArray.sort((a, b) => (a[1].Tot > b[1].Tot ? 1 : -1)).reverse();

        this.displayedCityCards
          .sort((a, b) => (a[1].Tot > b[1].Tot ? 1 : -1))
          .reverse();
      }
    },
    // sort the list of cities and the list of city displayed with cityCards by name
    sortCitiesByName() {
      if (this.up) {
        this.cityArray.sort();
        this.displayedCityCards.sort();
      } else {
        this.cityArray.sort().reverse();
        this.displayedCityCards.sort().reverse();
      }
    },
    sortCitiesByPopulation() {
      if (this.up) {
        this.greeneryReversed = !this.greeneryReversed;
        this.cityArray.sort((a, b) => (a[1].PopTot > b[1].PopTot ? 1 : -1));

        this.displayedCityCards.sort((a, b) =>
          a[1].PopTot > b[1].PopTot ? 1 : -1
        );
      } else {
        this.greeneryReversed = !this.greeneryReversed;
        this.cityArray
          .sort((a, b) => (a[1].PopTot > b[1].PopTot ? 1 : -1))
          .reverse();

        this.displayedCityCards
          .sort((a, b) => (a[1].PopTot > b[1].PopTot ? 1 : -1))
          .reverse();
      }
    },
    // sort the list of cities and the list of city displayed with cityCards by temperature deviation
    sortCitiesByTemperatureDeviation() {
      if (this.up) {
        this.temperatureDifferenceReversed =
          !this.temperatureDifferenceReversed;

        this.cityArray
          .sort((a, b) =>
            a[1].weather.meanDeviationYearly.C[
              a[1].weather.meanDeviationYearly.C.length - 1
            ] <
            b[1].weather.meanDeviationYearly.C[
              b[1].weather.meanDeviationYearly.C.length - 1
            ]
              ? 1
              : -1
          )
          .reverse();

        this.displayedCityCards
          .sort((a, b) =>
            a[1].weather.meanDeviationYearly.C[
              a[1].weather.meanDeviationYearly.C.length - 1
            ] <
            b[1].weather.meanDeviationYearly.C[
              b[1].weather.meanDeviationYearly.C.length - 1
            ]
              ? 1
              : -1
          )
          .reverse();
      } else {
        this.temperatureDifferenceReversed =
          !this.temperatureDifferenceReversed;

        this.cityArray.sort((a, b) =>
          a[1].weather.meanDeviationYearly.C[
            a[1].weather.meanDeviationYearly.C.length - 1
          ] <
          b[1].weather.meanDeviationYearly.C[
            b[1].weather.meanDeviationYearly.C.length - 1
          ]
            ? 1
            : -1
        );

        this.displayedCityCards.sort((a, b) =>
          a[1].weather.meanDeviationYearly.C[
            a[1].weather.meanDeviationYearly.C.length - 1
          ] <
          b[1].weather.meanDeviationYearly.C[
            b[1].weather.meanDeviationYearly.C.length - 1
          ]
            ? 1
            : -1
        );
      }
    },

    // sort the list of cities and the list of city displayed with cityCards by greenery
    sortCitiesByGreenery() {
      if (this.up) {
        this.greeneryReversed = !this.greeneryReversed;
        this.cityArray.sort((a, b) => (a[1].PercVeg > b[1].PercVeg ? 1 : -1));

        this.displayedCityCards.sort((a, b) =>
          a[1].PercVeg > b[1].PercVeg ? 1 : -1
        );
      } else {
        this.greeneryReversed = !this.greeneryReversed;
        this.cityArray
          .sort((a, b) => (a[1].PercVeg > b[1].PercVeg ? 1 : -1))
          .reverse();

        this.displayedCityCards
          .sort((a, b) => (a[1].PercVeg > b[1].PercVeg ? 1 : -1))
          .reverse();
      }
    },
    // show the list of cities
    showList() {
      if (!this.displayList) {
        this.displayList = true;
      }
    },
    // load More cityCards, 10 by 10
    loadMore() {
      // if there is more cityCard to display
      if (this.displayedCityCardNumber < this.cityArray.length) {
        // increment the number of displayed cityCards
        this.displayedCityCardNumber += 10;
        // create the new list displayed with cityCards
        this.displayedCityCards = this.cityArray.slice(
          0,
          this.displayedCityCardNumber
        );
      }
    },
    toggleActiveStyle(evt) {
      let sortBtns = document.getElementsByClassName(
        "city-list-controls-sort-btn-active"
      );
      for (let btn of sortBtns) {
        btn.classList.remove("city-list-controls-sort-btn-active");
      }
      evt.target.classList.toggle("city-list-controls-sort-btn-active");
    },
    updateColor(index) {
      if (index === 0) {
        if (this.klover) {
          if (!this.up) {
            return "#7fc183";
          } else {
            return "#b4b4b4";
          }
        } else if (this.temperature) {
          if (!this.up) {
            return "#ef5b33";
          } else {
            return "#32c3fa";
          }
        } else {
          return "";
        }
      } else if (index === 1) {
        if (this.klover) {
          if (!this.up) {
            return "#61a37d";
          } else {
            return "#8c96aa";
          }
        } else if (this.temperature) {
          if (!this.up) {
            return "#c35443";
          } else {
            return "#2ca6e3";
          }
        } else {
          return "";
        }
      } else if (index === 2) {
        if (this.klover) {
          if (!this.up) {
            return "#4c8d79";
          } else {
            return "#5a6f9e";
          }
        } else if (this.temperature) {
          if (!this.up) {
            return "#954c57";
          } else {
            return "#258ace";
          }
        } else {
          return "";
        }
      } else if (index === 3) {
        if (this.klover) {
          if (!this.up) {
            return "#3d767e";
          } else {
            return "#2f4e93";
          }
        } else if (this.temperature) {
          if (!this.up) {
            return "#6a4567";
          } else {
            return "#206eb8";
          }
        } else {
          return "";
        }
      } else if (index === 4) {
        if (this.klover) {
          if (!this.up) {
            return "#2f6182";
          } else {
            return "#153a8c";
          }
        } else if (this.temperature) {
          if (!this.up) {
            return "#3d3d79";
          } else {
            return "#1952a1";
          }
        } else {
          return "";
        }
      } else if (index === this.cityArray.length - 1) {
        if (this.klover) {
          if (!this.up) {
            return "#b4b4b4";
          } else {
            return "#7fc183";
          }
        } else if (this.temperature) {
          if (!this.up) {
            return "#32c3fa";
          } else {
            return "#ef5b33";
          }
        } else {
          return "";
        }
      } else if (index === this.cityArray.length - 2) {
        if (this.klover) {
          if (!this.up) {
            return "#8c96aa";
          } else {
            return "#61a37d";
          }
        } else if (this.temperature) {
          if (!this.up) {
            return "#2ca6e3";
          } else {
            return "#c35443";
          }
        } else {
          return "";
        }
      } else if (index === this.cityArray.length - 3) {
        if (this.klover) {
          if (!this.up) {
            return "#5a6f9e";
          } else {
            return "#4c8d79";
          }
        } else if (this.temperature) {
          if (!this.up) {
            return "#258ace";
          } else {
            return "#954c57";
          }
        } else {
          return "";
        }
      } else if (index === this.cityArray.length - 4) {
        if (this.klover) {
          if (!this.up) {
            return "#2f4e93";
          } else {
            return "#3d767e";
          }
        } else if (this.temperature) {
          if (!this.up) {
            return "#206eb8";
          } else {
            return "#6a4567";
          }
        } else {
          return "";
        }
      } else if (index === this.cityArray.length - 5) {
        if (this.klover) {
          if (!this.up) {
            return "#153a8c";
          } else {
            return "#2f6182";
          }
        } else if (this.temperature) {
          if (!this.up) {
            return "#1952a1";
          } else {
            return "#3d3d79";
          }
        } else {
          return "";
        }
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 800px) {
  .city-list-title {
    grid-template-columns: 3fr 3fr 3fr;
    gap: 1px;
  }

  .filter-class {
    font-size: 12px;
  }

  .city-list-title-class {
    align-self: end;
  }

  @media screen and (orientation: portrait) {
    .city-list-temperature-button {
      position: relative;
      left: 5px;
      justify-self: center;
    }
  }

  @media screen and (orientation: landscape) {
    .city-list-title {
      display: grid;
      grid-template-columns: 3fr 3fr 3fr 1fr;
      gap: 2px;
    }
  }
}
</style>
