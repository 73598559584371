<template>
  <v-container class="pa-0 pl-3" :fill-height="fill">
    <v-btn
      v-if="!temperature && !canopy"
      icon
      x-small
      aria-label="Informations"
      style="align-self: center"
      @click.prevent="greeneryInfos = true"
    >
      <v-icon v-if="!isMobile" size="1.5vw"> mdi-information-outline </v-icon>
      <v-icon v-if="isMobile" size="20px"> mdi-information-outline </v-icon>
    </v-btn>

    <v-dialog
      v-if="!temperature && !canopy"
      v-model="greeneryInfos"
      max-width="290"
    >
      <v-card>
        <v-card-title>{{ $t("informations.vegetation.title") }}</v-card-title>
        <v-card-text>
          {{ $t("informations.vegetation.content") }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn
      v-if="temperature"
      icon
      x-small
      aria-label="Informations"
      style="align-self: center"
      @click.prevent="temperatureInfos = true"
    >
      <v-icon v-if="!isMobile" size="1.5vw"> mdi-information-outline </v-icon>
      <v-icon v-if="isMobile" size="20px"> mdi-information-outline </v-icon>
    </v-btn>
    <v-dialog v-if="temperature" v-model="temperatureInfos" max-width="350">
      <v-card>
        <v-card-title>{{ $t("informations.temperature.title") }}</v-card-title>
        <v-card-text>
          {{ $t("informations.temperature.content1") }}{{ currentYear }}
          {{ $t("informations.temperature.content2") }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn
      v-if="canopy"
      icon
      x-small
      aria-label="Informations"
      style="align-self: center"
      @click.prevent="canopyInfos = true"
    >
      <v-icon v-if="!isMobile" size="1.5vw" color="white">
        mdi-information-outline
      </v-icon>
      <v-icon v-if="isMobile" size="20px" color="white">
        mdi-information-outline
      </v-icon>
    </v-btn>
    <v-dialog v-if="canopy" v-model="canopyInfos" max-width="290">
      <v-card>
        <v-card-title>{{ $t("informations.canopy.title") }}</v-card-title>
        <v-card-text> {{ $t("informations.canopy.content") }} </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "InfoBtn",
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    temperature: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    canopy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      greeneryInfos: false,
      temperatureInfos: false,
      canopyInfos: false,
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
