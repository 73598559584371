<template>
  <!-- Featured page -->
  <div class="featured-div">
    <div class="featured-text">
      <h1 class="featured-text-title">
        {{ $t("featured.title") }}
      </h1>
      <p class="featured-text-corps mb-10">
        {{ $t("featured.description") }}
      </p>
    </div>
    <!-- <ComparisonGraph /> -->
    <CityList class="featured-city-list" />
  </div>
</template>

<script>
import CityList from "@/components/Featured/CityList.vue";
// import ComparisonGraph from "@/components/Featured/ComparisonGraph.vue";

export default {
  name: "Featured",
  components: {
    // ComparisonGraph,
    CityList,
  },
  metaInfo() {
    var result;
    var resultTitle;
    if (window.location.pathname === "/classement") {
      resultTitle = "Végétation, climat : le classement des villes Klover";
      result = [
        {
          name: "title",
          content: "Végétation, climat : le classement des villes Klover",
        },
        {
          name: "description",
          content:
            "Quelles métropoles font la part belle à la végétation ? Dans quelles villes la hausse des températures est-elle la plus marquée jusqu'ici cette année ?",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://klover.city/classement",
        },
        {
          property: "og:title",
          content: "Végétation, climat : le classement des villes Klover",
        },
        {
          property: "og:description",
          content:
            "Quelles métropoles font la part belle à la végétation ? Dans quelles villes la hausse des températures est-elle la plus marquée jusqu'ici cette année ?",
        },
        { property: "og:image", content: this.$t("meta.home.img") },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://klover.city/classement",
        },
        {
          property: "twitter:title",
          content: "Végétation, climat : le classement des villes Klover",
        },
        {
          property: "twitter:description",
          content:
            "Quelles métropoles font la part belle à la végétation ? Dans quelles villes la hausse des températures est-elle la plus marquée jusqu'ici cette année ?",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/Classement.jpg",
        },
      ];
    } else {
      resultTitle = "Vegetation and climate : Klover city rankings";
      result = [
        {
          name: "title",
          content: "Vegetation and climate : Klover city rankings",
        },
        {
          name: "description",
          content:
            "Which metropolises give pride of place to vegetation ? Which have witnessed the sharpest rise in temperatures so far this year ?",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://klover.city/featured",
        },
        {
          property: "og:title",
          content: "Vegetation and climate : Klover city rankings",
        },
        {
          property: "og:description",
          content:
            "Which metropolises give pride of place to vegetation ? Which have witnessed the sharpest rise in temperatures so far this year ?",
        },
        { property: "og:image", content: this.$t("meta.home.img") },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://klover.city/featured",
        },
        {
          property: "twitter:title",
          content: "Vegetation and climate : Klover city rankings",
        },
        {
          property: "twitter:description",
          content:
            "Which metropolises give pride of place to vegetation ? Which have witnessed the sharpest rise in temperatures so far this year ?",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/Classement-en.jpg",
        },
      ];
    }
    return {
      title: resultTitle,
      meta: result,
    };
  },
};
</script>

<style scoped>
@media screen and (max-width: 800px) {
  .featured-text-title {
    font-size: 24px;
  }

  .featured-text-corps {
    font-size: 14px;
  }

  .featured-city-list {
    margin-top: inherit;
  }
}
</style>
